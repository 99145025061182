import { useState } from 'react'
import Select from '@mui/material/Select'
import { SelectChangeEvent } from "@mui/material";
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import ListItemText from '@mui/material/ListItemText'
import classes from './RSelectInputField.module.scss'


enum FieldSize {
  "small" = "210px",
  "large" = "450px",
}

type RInputFieldProps = {
  value: any
  label: string
  itemsList: {name: string, value: any}[] | undefined
  required?: boolean
  register: {}
  error?: any
  errorMessage?: string
  name: string
  size?: 'small' | 'large'
  multiple?: boolean
  withCheckboxes?: boolean
  withNoSelectOption?: boolean
  privacy?: 'allow' | 'mask' | 'hidden' | 'mask-user-input'
  onChange?: (e: SelectChangeEvent<any>) => void
}


const RSelectInputField = ({value, label, itemsList, name, errorMessage, register, error, required=false, size="large", multiple=false, withCheckboxes=false, withNoSelectOption=false, privacy=undefined, onChange}: RInputFieldProps) => {

  const [selectedValues, setSelectedValues] = useState<string | string[]>('')

  return (
    <div className={classes.select}>
      <label className={classes.label}>{label}{required && "*"}</label>
      <Select
        defaultValue={value}
        style={{width: FieldSize[size]}}
        displayEmpty
        {...register}
        classes={{
          select: classes.select
        }}
        data-dd-action-name={privacy === 'mask' || privacy === 'mask-user-input' ? label : null}
        className={`${ error && 'is-invalid' } ${classes.field}`}
		onChange={onChange}
        inputProps={{
            'data-dd-privacy': privacy
        }}
        MenuProps={{
            className: privacy === undefined ? '' : `dd-privacy-${privacy}`
        }}
        SelectDisplayProps={{
            className: privacy === undefined ? '' : `dd-privacy-${privacy}`
        }}
        multiple={multiple}
        renderValue={(selected) => {
          setSelectedValues(selected)
          return (
            (
              multiple ?
              selected.map((select: any) => itemsList?.find((item) => item.value === select)?.name).join(', '):
              itemsList?.find((item) => item.value === selected)?.name
            ) || "Select " + name
          )
        }}
      >
        <MenuItem disabled value="">
          Select {name}
        </MenuItem>

        {withNoSelectOption && (
          <MenuItem key="" value="" classes={{selected: classes.selected}}>
            No Selection
          </MenuItem>
        )}

        {
          itemsList?.map((item, index) => {
            return (
              <MenuItem key={index} value={item.value} classes={{selected: classes.selected}}>
                {
                  withCheckboxes && <Checkbox classes={{checked: classes.checked_checkbox}} checked={selectedValues.indexOf(item.value) > -1} />
                }
                <ListItemText primary={item.name} data-dd-action-name={ privacy === 'mask' || privacy === 'mask-user-input' ? label : null} />
              </MenuItem>
            )
          })
        }
      </Select>
      {
        error && (
          (error.type === "required") ?
          (
            <div className={classes.feedback}>
              { errorMessage ? errorMessage : `${name} can't be blank.`}
            </div>
          ) :
          (
            <div className={classes.feedback}>
            { errorMessage ? errorMessage : `Please provide a valid ${name}.`}
            </div>
          )
        )
      }
    </div>
  )
}

export default RSelectInputField
